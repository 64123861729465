<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="7" class="d-none d-md-none d-lg-flex pa-0">
        <v-img
          src="../assets/welcome-card.jpg"
          dark
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.8)"
          class="align-center text-center"
        >
          <p class="text-h3 px-10">
            Con tecnología venimos a revolucionar la forma de hacer negocios
          </p>
        </v-img>
      </v-col>
      <v-col
        cols="12"
        lg="5"
        class="login-part d-flex align-center justify-center"
      >
        <v-row no-gutters class="align-start">
          <v-col
            cols="12"
            class="login-part d-flex align-center justify-center flex-column"
          >
            <div class="pt-sm-0">
              <v-container>
                <v-row class="flex-column">
                  <v-col class="text-center">
                    <img
                      v-if="$vuetify.theme.dark"
                      src="../assets/ssencial-logo-white.svg"
                      width="40px"
                      alt="ssencial"
                    />
                    <img
                      v-else
                      src="../assets/ssencial-logo.svg"
                      width="40px"
                      alt="ssencial"
                    />
                  </v-col>
                  <v-col>
                    <p class="text-h5 text-center font-weight-medium mb-0">
                      Bienvenido a
                    </p>
                    <p class="text-h4 text-center font-weight-medium mt-0">
                      SSENCIAL
                    </p>
                  </v-col>
                  <v-form ref="log" v-model="valid" lazy-validation>
                    <v-col class="pb-0">
                      <v-text-field
                        outlined
                        type="email"
                        ref="email"
                        v-model="email"
                        :rules="emailRules"
                        label="Correo"
                        @keyup.enter.native="$refs.password.focus()"
                        required
                      ></v-text-field>
                      <v-text-field
                        outlined
                        ref="password"
                        v-model="password"
                        :rules="passRules"
                        label="Contraseñas"
                        required
                        @keyup.enter.native="
                          $refs.password.blur();
                          login();
                        "
                        :append-icon="
                          showPassword ? 'visibility' : 'visibility_off'
                        "
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                      ></v-text-field>
                    </v-col>
                    <v-col class="d-flex justify-end pt-0">
                      <v-btn
                        class="text-capitalize"
                        block
                        :width="$vuetify.breakpoint.mdAndUp ? 300 : '90vw'"
                        max-width="300"
                        color="primary"
                        :loading="isFetching"
                        @click="login"
                      >
                        Login
                      </v-btn>
                    </v-col>
                    <v-col class="text-center pt-0">
                      <v-btn
                        large
                        text
                        class="text-capitalize primary--text"
                        @click="recoverPassword"
                      >
                        ¿Olvidaste tu contraseña?
                      </v-btn>
                    </v-col>
                  </v-form>
                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { emailValid, required, minLength } from "@/services/ValidatorService";
import { mapActions } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      valid: true,
      email: "",
      emailRules: [emailValid, required],
      password: "",
      showPassword: false,
      passRules: [required, minLength(8)],
      isFetching: false
    };
  },
  methods: {
    ...mapActions("user", ["loginWithEmailAndPassword"]),
    async login() {
      const email = this.email;
      const password = this.password;
      if (this.$refs.log.validate()) {
        this.isFetching = true;
        await this.loginWithEmailAndPassword({ email, password });
        setTimeout(() => {
          this.isFetching = false;
        }, 2000);
      }
    },
    recoverPassword() {
      this.$router.push({ name: "recoverPassword" });
    }
  }
};
</script>

<style scoped>
.login-part {
  width: 100%;
  height: 96vh;
}
</style>
